import Vue from 'vue'
import Vuex from 'vuex'
import i18n from "@/i18n";

import createPersistedState from "vuex-persistedstate";

import tokenModule from "./tokenModule.js";
import apiModule from "./api/apiModule.js";
import socketModule from "./socketModule.js";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appLanguage: localStorage.getItem("appLanguage") || process.env.VUE_APP_I18N_LOCALE || 'en',
  },
  getters: {
    getAppLanguage: (state) => state.appLanguage,
  },
  mutations: {
    setAppLanguage(state, language) {
      state.appLanguage = i18n.locale = language;
      localStorage.setItem("appLanguage", language);
    },
  },
  actions: {
  },
  modules: {
    token: tokenModule,
    api: apiModule,
    socket: socketModule,
  },

  plugins: [ createPersistedState() ],
})
