<template>
    <div id="app" :lang="$i18n.locale">
        <ResizeBox>
            <PageContainer>
                  <!-- <transition name="fade" mode="out-in"> -->
                      <router-view/>
                  <!-- </transition> -->
            </PageContainer>
        </ResizeBox>
    </div>
</template>

<script>
import ResizeBox from "@/components/Layout/ResizeBox";
import PageContainer from "@/components/Layout/PageContainer";

export default {
  name: "App",
  components: {
    ResizeBox,
    PageContainer,
  },
  // async created(){
    // this.$i18n.locale = "en";
  // },
}
</script>

<style lang="scss">

/* font */
@font-face{
  font-family: enTitle;
  src: url("~@/assets/font/eng_title/AmaticSC-Regular.ttf");
  font-weight: 400;
  unicode-range: U+0000-007F;
}

@font-face{
  font-family: enTitle;
  src: url("~@/assets/font/eng_title/AmaticSC-Bold.ttf");
  font-weight: 700;
  unicode-range: U+0000-007F;
}

@font-face{
  font-family: enContent;
  src: url("~@/assets/font/eng_content/GloriaHallelujah-Regular.ttf");
  font-weight: 400;
  unicode-range: U+0000-007F;
}




// @font-face{
//   font-family: cnTitle;
//   src: url("~@/assets/font/cn_title/Yozai-Light.ttf");
//   font-weight: 100;
// }

@font-face{
  font-family: cnTitle;
  src: url("~@/assets/font/cn_title/Yozai-Regular.ttf");
  font-weight: 400;
}

@font-face{
  font-family: cnTitle;
  src: url("~@/assets/font/cn_title/Yozai-Medium.ttf");
  font-weight: 500;
}

@font-face{
  font-family: cnTitle;
  src: url("~@/assets/font/cn_title/Yozai-Medium.ttf");
  font-weight: bold;
}

// @font-face{
//   font-family: cnTitle;
//   src: url("~@/assets/font/cn_title/Yozai-Bold.ttf");
//   font-weight: 700;
// }


@font-face{
  font-family: cnContent;
  src: url("~@/assets/font/cn_content/CEF Fonts CJK Mono.ttf");
  font-weight: 400;
}





:lang(zh),
:lang(cn){
  h1, h2, h3{
    font-family: cnTitle;
    font-weight: 500;
  }

  * {
    font-family: cnContent;
  }
}

.lang-zh, .lang-cn {
  font-family: cnContent !important;

  * {
    font-family: cnContent !important;
  }

  h1, h2, h3,
  &h1, &h2, &h3 {
    font-family: cnTitle !important;
  }
}


:lang(en){
  h1, h2, h3{
    font-family: enTitle;
  }
  * {
      font-family: enContent;
  }
}

.lang-en {
    font-family: enContent !important;

    * {
      font-family: enContent !important;
    }

    h1, h2, h3,
    &h1, &h2, &h3 {
      font-family: enTitle !important;
    }
}


#app{
  font-size: 40px;
  // font-size: min( 3vw, 40px );
  background-color: black;
}


html, body, #app{
  padding: 0;
  margin: 0;

  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

#app{
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
}

// transition animation
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-leave,
.fade-enter-to {
  opacity: 1;
}

.center-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

// .no-select {
*:not( input ) {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

* {
  -webkit-tap-highlight-color: transparent;
  touch-action: none;
  pointer-events: none;
}

input, button, a {
  pointer-events: auto;
}

</style>
