import Store from "@/store/index.js";
import API from "../init.js";
import router from "@/router";

export default {
    async matchCreate( state, gameInfo ) {

      // console.log("gameInfo", JSON.stringify( gameInfo ) );

      try{
          const tokens = Store.getters.tokens;

          const response = await API.post(
            `${"match/create"}`,
            {
                game: gameInfo.gameId,
                difficulty: gameInfo.levelId,
            },
            {
              headers: {
                Authorization: "Bearer " + tokens.accessToken,
              },
            }
          );

          // set token
          if( response?.data?.result ){
              return response?.data;
          }else{
            return false;
          }

      } catch( err ){

          // alert( err.response.data.error );
          if( err?.response?.status == 401 && !gameInfo.isRetry ){
              if( await Store.dispatch("refreshToken") ){
                console.log("Refresh Token");

                // try again
                gameInfo.isRetry = true;
                return await Store.dispatch("matchCreate", gameInfo );
              }else{
                console.log("invalid refreshToken");
                return "invalid refreshToken";
              }
          } else if( err.message == "Network Error" ){

              return false;
          } else {

              router.push({ name: "admin-login" });
              return false;
          }

          return false;
      }

    }
}
