import Store from "@/store/index.js";
import API from "../init.js";

export default {
    async adminLogout( state ) {
      try{

          if( !Store.getters.isLogin ){
            console.log( "Not Login" );
            return false;
          }


          const tokens = Store.getters.tokens;

          const response = await API.post(
            `${"adminAccess/logout"}`,
            {
              "refreshToken": tokens.refreshToken,
            },
            {
              headers: {
                Authorization: "Bearer " + tokens.accessToken,
              },
            }
          );

          // reset token
          // if( response?.data?.result ){
          //     Store.commit("removeTokens");
          //     return true;
          // }else{
          //     return false;
          // }

      } catch( err ){
          console.log( err );
          // alert( err.response.data.error );
          // return null;
      } finally{
          Store.commit("removeTokens");
      }

    }
}
