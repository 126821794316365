import Store from "@/store/index.js";
import API from "../init.js";

export default {
    async adminLogin( state, password ) {
      try{
          const response = await API.post(
            `${"adminAccess/login"}`,
            {
              "password": password,
            }
          );

          // set token
          if( response?.data?.result ){
              Store.commit("setTokens",{
                accessToken: response.data.accessToken,
                refreshToken: response.data.refreshToken,
              });

              return true;
          }else{
            return false;
          }

      } catch( err ){
          console.log( err );
          alert( err.response.data.error );
          return null;
      }

    }
}
