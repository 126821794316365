export default {
    state: {
      uuid: null,
      accessToken: null,
      refreshToken: null,
    },
    mutations: {
        setTokens(state, payload) {
          if (payload.accessToken != null) {
              state.accessToken = payload.accessToken;
          }

          if (payload.refreshToken != null) {
              state.refreshToken = payload.refreshToken;
          }
        },
        removeTokens(state) {
          state.accessToken = null;
          state.refreshToken = null;
        },
    },
    getters: {
      uuid: (state) => {
        if( !state.uuid ){
            // generate new uuid
            state.uuid = state.uuid = Date.now() + "" + Math.random();
        }

        return state.uuid;
      },
      tokens: (state) => {
          return {
              accessToken: state.accessToken,
              refreshToken: state.refreshToken,
          };
      },
      isLogin: (state) => {
          return !!state.accessToken;
      },
    }
}
