import i18n from "@/i18n";
import store from "@/store";
import router from "@/router";

import Vue from 'vue';
import VueSocketIO from 'vue-socket.io';
import SocketIO from "socket.io-client";


export default {
  state: {
    // socket: null,
    gameRoomInfo: {
      status: 0, // wait player join -> wait player tutorial ready // wait player finish game // show answer // show leaderboard // show next action
      players: [],
      matchId: null,
      game: null,
      difficulty: null,
      timeCounter: 0,
    },

    countDownInterval: null,
  },
  getters: {
    gameId( state ) {
      return state.gameRoomInfo.game;
    },
    difficulty( state ) {
      return state.gameRoomInfo.difficulty;
    },
    roomStatus( state ){
      return state.gameRoomInfo.status;
    },
    playersList( state ){
      return state.gameRoomInfo.players;
    },
    sortedPlayersList( state ){
      return state.gameRoomInfo.players.sort( function( p1, p2 ){

          if(  p1.score.correctCount != p2.score.correctCount ){
            return p1.score.correctCount - p2.score.correctCount;
          }else if( p1.score.timeUsed != p2.score.timeUsed ){
            return p1.score.timeUsed - p2.score.timeUsed;
          }else{
            return 0;
          }

      });
    },
    timeCounter( state ){
      return state.gameRoomInfo.timeCounter;
    }
  },
  mutations: {
    // setSocket( state, newSocket ){
    //   console.log("set socket");
    //   state.socket = newSocket;
    // },
    setGameRoomInfo( state, data ){
      state.gameRoomInfo.matchId = data._id || state.gameRoomInfo.matchId;
      state.gameRoomInfo.game = data.game;
      state.gameRoomInfo.difficulty = data.difficulty;
      state.gameRoomInfo.timeCounter = data.duration;
    },
    resetGameRoom( state ){
        console.log("game room reset");
        state.gameRoomInfo = {
          status: 0,
          players: [],
          matchId: null,
          game: null,
          difficulty: null,
          timeCounter: 0,
        }
    },
    initPlayer( state, player ){
       console.log( "init player", player );

       player.isReadyGame = false;
       player.isFinishedGame = false;
       player.score = {
         correctCount: 0,
         wrongCount: 0,
         timeUsed: 0,
       };
    },


    // admin event listener
    admin_socket_mutation_connect( state, data ){
      console.log( "admin connect", data );
    },
    admin_socket_mutation_message( state, data ){
      console.log( "admin message", data );
    },
    admin_socket_mutation_playerJoined( state, data ){
      console.log( "admin playerJoined", data );

      const player = data["newPlayer"];

      // check is existed player
      if( state.gameRoomInfo.players.find( p => p.name == player.name ) ){
          console.log("dulplicate player");
          return;
      }

      player.displayName = player.displayName.replace("+"," ");
      this.commit( "initPlayer", player );
      state.gameRoomInfo.players.push( player );

      this._vm.$socket.emit("playerJoined", state.gameRoomInfo.players );
    },

    admin_socket_mutation_displayTutorial( state, data ){
      console.log( "admin displayTutorial", data );
      state.gameRoomInfo.status = 1;
    },

    admin_socket_mutation_readyGame( state, data ){
      console.log( "admin readyGame", data );

      const playerInfo = data.readyPlayer;
      const targetPlayer = state.gameRoomInfo.players.find( p => p.name == playerInfo.name );

      console.log("target", targetPlayer);

      if( targetPlayer ){
          targetPlayer.isReadyGame = true;
      }
      else{
          console.error("player not exist:", playerInfo );
          return false;
      }


      // if all player is ready => start game automatically
      if(
        state.gameRoomInfo.status == 1 &&
        state.gameRoomInfo.players.filter( p => !p.isReadyGame ).length == 0
      ){
          this._vm.$socket.emit("gameStart", state.gameRoomInfo.timeCounter );
      }

    },
    admin_socket_mutation_gameStart( state, data ){
      console.log( "admin gameStart", data );
      state.gameRoomInfo.status = 2;

      this._vm.$socket.emit("gameCounter", state.gameRoomInfo.timeCounter );

      if( state.countDownInterval ){
          clearInterval( state.countDownInterval );
      }

      state.countDownInterval = setInterval( function(){
          this._vm.$socket.emit("gameCounter", -- state.gameRoomInfo.timeCounter );

          if( state.gameRoomInfo.timeCounter <= 0 ){
              clearInterval( state.countDownInterval );
          }
      }.bind( this ), 1000 );
    },
    admin_socket_mutation_gameCounter( state, data ){
      console.log( "admin gameCounter", data );
      // state.gameRoomInfo.gameCounter
    },
    admin_socket_mutation_submitAnswer( state, data ){
      console.log( "admin submitAnswer", data );

      const targetPlayer = state.gameRoomInfo.players.find( p => p.name == data.player.name );
      targetPlayer.score = data.answer;
      targetPlayer.isFinishedGame = true;

    },
    admin_socket_mutation_displayAnswer( state, data ){
      console.log( "admin displayAnswer", data );

      state.gameRoomInfo.status = 3;

      if( state.countDownInterval ){
          clearInterval( state.countDownInterval );
      }
    },
    admin_socket_mutation_displayLeaderboard( state, data ){
      console.log( "admin displayLeaderboard", data );

      state.gameRoomInfo.status = 4;
    },
    admin_socket_mutation_waitForNewGame( state ){
      console.log( "admin waitForNewGame", state );

      // reset all player

      state.gameRoomInfo.players.forEach( function( p ){
        this.commit( "initPlayer", p );
      }.bind(this) );
    },
    admin_socket_mutation_changeGame( state, data ){
      console.log( "admin changeGame", state, data );

      state.gameRoomInfo.status = 0;
      state.gameRoomInfo.game = data.match.game;
      state.gameRoomInfo.difficulty = data.match.difficulty;

      router.push({ name: "admin-game-room"});
    },
    admin_socket_mutation_disband( state, data ){
      console.log( "admin disband", state, data );
    },
    admin_socket_mutation_disconnect( state, data ){
      console.log( "admin disconnect", state, data );

      if( state.countDownInterval ){
          clearInterval( state.countDownInterval );
      }


      this.commit("resetGameRoom");
      // router.push({"name": "admin-panel"});
      router.push({ name: "admin-panel" });
    },

    // user event listener
    user_socket_mutation_connect( state, data ){
      console.log( "user connect", data );
    },
    user_socket_mutation_message( state, data ){
      console.log( "user message", data );
    },
    user_socket_mutation_playerJoined( state, data ){
      console.log( "user playerJoined", data );

      state.gameRoomInfo.players = data.playerList;

      // const player = data["newPlayer"];
      // player.displayName = player.displayName.replace("+"," ");
      // player.isReadyGame = false;
      // player.isFinishedGame = false;
      //
      // state.gameRoomInfo.players.push( player );
    },
    user_socket_mutation_displayTutorial( state, data ){
      console.log( "user displayTutorial", data );

      state.gameRoomInfo.status = 1;

      router.push({
        name: state.gameRoomInfo.game,
        params: {
          levelId: state.gameRoomInfo.difficulty,
        }
      });
    },
    user_socket_mutation_readyGame( state, data ){
      console.log( "user readyGame", data );
    },
    user_socket_mutation_gameStart( state, data ){
      console.log( "user gameStart", data );

      state.gameRoomInfo.status = 2;
    },
    user_socket_mutation_gameCounter( state, data ){
      console.log( "user gameCounter", data );

      state.gameRoomInfo.timeCounter = data.counter;
    },
    user_socket_mutation_submitAnswer( state, data ){
      console.log( "user submitAnswer", data );
    },
    user_socket_mutation_displayAnswer( state, data ){
      console.log( "user displayAnswer", data );

      state.gameRoomInfo.status = 3;
    },
    user_socket_mutation_displayLeaderboard( state, data ){
      console.log( "user displayLeaderboard", data );

      state.gameRoomInfo.players = data.leaderboard;
      state.gameRoomInfo.status = 4;
    },
    user_socket_mutation_waitForNewGame( state ){
      console.log( "user waitForNewGame", state );

      router.push({ name: "waiting-room" });
    },
    user_socket_mutation_changeGame( state, data ){
      console.log( "user changeGame", state, data );

      state.gameRoomInfo.status = 0;
      state.gameRoomInfo.game = data.match.game;
      state.gameRoomInfo.difficulty = data.match.difficulty;
    },
    user_socket_mutation_disband( state, data ){
      console.log("user disband", state, data );

      router.push({ name: "menu" });
    },
  },
  actions: {

    async connect_user_socket( context, params ){

      params.role = "USER";
      params.token = "test";
      params.deviceNumber = context.getters.uuid;
      params.lang = i18n.locale;

      const paramStr = new URLSearchParams( params ).toString();

      // console.log( params );
      // console.log( paramStr );

      const socket = SocketIO(`https://library.vidarstudio.com:2096/?${ paramStr }`, {
          "path": "/socket.io",
          "forceNew": true,
          "reconnectionAttempts": 3,
          "timeout": 2000
      });

      Vue.use(new VueSocketIO({
          debug: true,
          connection: socket,
          vuex: {
            store,
            actionPrefix: "user_socket_action_",
            mutationPrefix: "user_socket_mutation_"
          },
        })
      );

      // init game room
      context.commit("resetGameRoom");

      return true;

    },


    async connect_admin_socket( context, params ){
        params.role = "ADMIN";
        params.token = context.getters.tokens.accessToken;

        const paramStr = new URLSearchParams( params ).toString();

        const socket = SocketIO(`https://library.vidarstudio.com:2096/?${ paramStr }`, {
          "path": "/socket.io",
          "forceNew": true,
          "reconnectionAttempts": 3,
          "timeout": 2000
        });

        Vue.use( new VueSocketIO({
            debug: true,
            connection: socket,
            vuex: {
              store,
              actionPrefix: "admin_socket_action_",
              mutationPrefix: "admin_socket_mutation_"
            },
        }));

        context.commit("resetGameRoom");

        return true;

    },



    // SOCKET_action_connect( state, data ){
    //   console.log( "connecta", state, data );
    // }
  },

}
