import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from "@/store/index.js";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    props: true,
    component: () => import(/* webpackChunkName: "home" */ '../views/main/HomeView.vue')
  },
  {
    path: '/leaderboard',
    name: 'leaderboard',
    component: () => import(/* webpackChunkName: "leaderboard" */ '../views/main/LeaderboardView.vue')
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import(/* webpackChunkName: "menu" */ '../views/main/MenuView.vue')
  },
  {
    path: '/select-avatar',
    name: 'select-avatar',
    component: () => import(/* webpackChunkName: "select-avatar" */ '../views/main/SelectAvatarView.vue')
  },
  {
    path: '/waiting-room',
    name: 'waiting-room',
    component: () => import(/* webpackChunkName: "waiting-room" */ '../views/main/WaitingRoomView.vue')
  },
  {
    path: '/admin',
    name: 'admin-login',
    component: () => import(/* webpackChunkName: "admin-login" */ '../views/admin/LoginView.vue'),
  },
  {
    path: '/admin/game-room',
    name: 'admin-game-room',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "admin-game-room" */ '../views/admin/GameRoomView.vue')
  },
  {
    path: '/admin/panel',
    name: 'admin-panel',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "admin-panel" */ '../views/admin/PanelView.vue')
  },
  {
    path: '/game1/:levelId',
    name: 'game1',
    props: true,
    component: () => import(/* webpackChunkName: "game1" */ '../views/game/Game1View.vue')
  },
  {
    path: '/game2/:levelId',
    name: 'game2',
    props: true,
    component: () => import(/* webpackChunkName: "game2" */ '../views/game/Game2View.vue')
  },
  {
    path: '/game3/:levelId',
    name: 'game3',
    props: true,
    component: () => import(/* webpackChunkName: "game3" */ '../views/game/Game3View.vue')
  },
  {
    path: '/game4/:levelId',
    name: 'game4',
    props: true,
    component: () => import(/* webpackChunkName: "game4" */ '../views/game/Game4View.vue')
  },
  {
    path: '/game5/:levelId',
    name: 'game5',
    props: true,
    component: () => import(/* webpackChunkName: "game5" */ '../views/game/Game5View.vue')
  },
]

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
    if ( to.matched.some((record) => record.meta.requiresAuth )
        && !Store.getters.isLogin ) {
        next({ name: "admin-login" });
        return;
    }

    next();
});


export default router
