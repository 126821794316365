import adminLogin from "@/store/api/actions/adminLogin.js";
import adminLogout from "@/store/api/actions/adminLogout.js";
import matchCreate from "@/store/api/actions/matchCreate.js";
import matchJoinFirst from "@/store/api/actions/matchJoinFirst.js";
import matchJoinLast from "@/store/api/actions/matchJoinLast.js";
import refreshToken from "@/store/api/actions/refreshToken.js";


export default {

    state: {
      status: "",
      errorMsg: "",
    },
    mutations: {
      action_request: (state) => {
        state.status = "loading";
      },
      action_success: (state) => {
        state.status = "success";
      },
      action_error: (state, { errorMsg }) => {
        state.status = "error";
        state.errorMsg = errorMsg;
      },
    },
    getters: {
      apiStatus: (state) => state.status,
    },
    actions: {
      ...adminLogin,
      ...adminLogout,
      ...matchCreate,
      ...matchJoinFirst,
      ...matchJoinLast,
      ...refreshToken,
    },

}
