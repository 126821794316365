import Store from "@/store/index.js";
import API from "../init.js";
import router from "@/router";

import i18n from "@/i18n";

export default {
    async refreshToken( state ) {
      try{

          const tokens = Store.getters.tokens;

          const response = await API.post(
            `${"adminAccess/refreshToken"}`,
            {
              "refreshToken": tokens.refreshToken,
            }
          );

          // set token
          if( response?.data?.result ){
              Store.commit("setTokens",{
                accessToken: response.data.accessToken,
                refreshToken: response.data.refreshToken,
              });

              return true;
          }else{
              return false;
          }

      } catch( err ){
          console.log( err );

          if( err?.response?.data?.error == "Invalid refreshToken" ){
              Store.commit("removeTokens");
              alert( i18n.t( "alert.SessionExpired" ) );
          }else{
              alert( err?.response?.message || "Unknown error" );
          }

          router.push({ name: "admin-login" });
          return false;
      }

    }
}
