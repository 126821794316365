<template lang="html">
    <div class="container background">
        <slot />
    </div>
</template>

<script>
export default {
  name: "PageContainer",
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  max-width: 1366px;
  max-height: 1024px;

  overflow: hidden;

  position: relative;
}

.background {
  background-image: url('~@/assets/img/bg_general.jpg');
  background-size: cover;
  background-position: center;
}
</style>
