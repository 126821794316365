import Store from "@/store/index.js";
import Router from "@/router/index.js";
import axios from "axios";


const baseURL = "https://library.vidarstudio.com:2096/api/";

const axiosApiInstance = axios.create({ baseURL });

export default axiosApiInstance;

//
// axiosApiInstance.interceptors.request.use(
//   function (config) {
//     console.log("AXIOS REQUEST:", JSON.parse( JSON.stringify( config ) ) );
//     return config;
//   },
//   function (error) {
//     console.log("AXIOS REQUEST ERROR", JSON.parse( JSON.stringify( error ) ) );
//     return Promise.reject(error)
//   }
// );
//
// axiosApiInstance.interceptors.response.use(
//   function( response ){
//     console.log("AXIOS RESPONSE", JSON.parse( JSON.stringify( response ) ) );
//     return response;
//   },
//   async function( error ){
//     console.log("AXIOS RESPONSE ERROR", JSON.parse( JSON.stringify( error ) ) );
//     const originalRequest = error.config;
//     const response = error.response;
//
//     if( response && response?.status === 401 && !originalRequest._retry ){
//         originalRequest._retry = true;
//
//         if (await Store.dispatch("refreshToken")) {
//
//           // change token
//           originalRequest.headers.Authorization = "Bearer " + Store.getters.tokens.accessToken;
//
//           if (originalRequest.data) {
//             const payload = JSON.parse(originalRequest.data);
//             originalRequest.data = payload;
//           }
//
//
//           console.log( "B4 axios: ", originalRequest );
//           return axios( originalRequest );
//           // return axiosApiInstance({
//           //   method: originalRequest.method,
//           //   url: originalRequest.url,
//           //   data: originalRequest.data,
//           //   headers: originalRequest.headers,
//           //   _retry: originalRequest._retry,
//           // });
//         }else{
//           console.log("refresh fail");
//           return Promise.reject(error);
//         }
//     }else{
//         return Promise.reject(error);
//     }
//   }
// )






// axiosApiInstance.interceptors.response.use(function (response) {
//
//   console.log("AXIOS RESPONSE");
//   return response;
// }, async function (error) {
//
//   console.log("AXIOS RESPONSE ERROR", error );
//   if (error.response) {
//
//     // server responded status code falls out of the range of 2xx
//     switch (error.response.status) {
//       case 400:
//         {
//           const { message } = error.response.data;
//           console.error(`${error.response.status}: ${message || "Data error"}`);
//         }
//
//         break
//
//       case 401:
//         {
//
//           console.log("Try refresh token: ");
//
//           if( await Store.dispatch("refreshToken")){
//
//           }
//
//           // const refreshTokenUrl = `${ baseURL }adminAccess/refreshToken`
//           // if (error.config.url !== refreshTokenUrl) {
//           //
//           //   var originalRequest = error.config;
//           //
//           //   return axios
//           //     .post(refreshTokenUrl, {
//           //       "refreshToken": Store.getters.tokens.refreshToken,
//           //     })
//           //     .then((response) => {
//           //
//           //       // console.log("===", response, response.data.accessToken, response.data.refreshToken );
//           //
//           //       Store.commit("setTokens",{
//           //         accessToken: response.data.accessToken,
//           //         refreshToken: response.data.refreshToken,
//           //       });
//           //
//           //       // console.log( "ORI:", JSON.stringify( originalRequest ) );
//           //       console.log("ori2:", originalRequest.headers.Authorization);
//           //
//           //
//           //       originalRequest.headers.Authorization = "Bearer " + response.data.accessToken;
//           //       console.log( originalRequest );
//           //       console.log( originalRequest.headers );
//           //       console.log( originalRequest.headers.Authorization );
//           //       // console.log("token:", Store.getters.tokens.accessToken, Store.getters.tokens.refreshToken );
//           //       console.log( "ORI3:", JSON.stringify( originalRequest ) );
//           //
//           //       return axios( originalRequest );
//           //     })
//           //     .catch((err) => {
//           //       console.error("Tokens expire:", err);
//           //       // Store.commit("removeTokens");
//           //       // Router.push({ name: "admin-login" });
//           //       return Promise.reject(error);
//           //     })
//           // }
//         }
//
//         break
//
//       case 404:
//         console.error(`${error.response.status}: Not found`);
//         break;
//
//       case 500:
//         console.error(`${error.response.status}: Server error`);
//         break;
//
//       default:
//         console.error(`${error.response.status}: Server error`);
//         break;
//     }
//   } else {
//     // Something happened in setting up the request that triggered an Error
//     if (error.code === 'ECONNABORTED' && error.message && error.message.indexOf('timeout') !== -1) {
//       // request time out will be here
//       console.error("API timeout");
//     } else {
//       // shutdonw api server
//       console.error("Server error");
//     }
//   }
//
//   return Promise.reject(error)
// })
