import Store from "@/store/index.js";
import API from "../init.js";

export default {
    async matchJoinLast( state ) {
      try{

          const response = await API.post(
            `${"match/join/last"}`,
            {
                "deviceNumber": Store.getters.uuid,
            },
            {
              headers: {
                Authorization: "Bearer " + "test",
              },
            }
          );

          if( response?.data?.result ){
              return response?.data;
          }else{
              alert( response.data.reason );
              return false;
          }

      } catch( err ){
          console.log( err );
          alert( err.response.data.error || err.response.data.reason );
          return null;
      }

    }
}
